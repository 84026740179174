.footer {
    background-color: $color-green;
    padding-top: 3.950em;
    color: $color-white;
    padding-inline: 6.5rem;

    @include breakpoint(MS){
        padding-inline: 2.5rem;
    }

    @include breakpoint(S){
        padding-top: 1.950em;
        padding-inline: unset;
    }


    .footer-container{
        @include breakpoint(S){
            padding-inline: 2rem;
        }
        >div{
            @include breakpoint(S){
                width: 47%;
            }
            @include breakpoint(XS){
                width: 100%;
            }

            .phone-container{
                display: flex;
                gap: 0.94rem;
                @include breakpoint(ML){
                    flex-direction: column;
                }

            }
        }
        @include breakpoint(S){
            gap: 2.5rem;

        }
    }

    @include breakpoint(XS) {
        padding-top: 2rem;
        overflow: hidden;
    }

    &__logo {
        margin-bottom: 2.5rem;
        display: block;
    }

    &__nav {
        padding-left: 6.250em;
        font-family: $font-stack-secondary;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        @include size-XS;

        @include breakpoint(XS) {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
        }

        li {
            display: block;
            margin-bottom: 1.5em;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(XS) {
                width: 50%;
            }
        }

        a {
            display: block;
            color: inherit;
            font-family: $font-stack-narrow;
            @include size-S;
            font-weight: 600;

            &:hover {
                color: $color-white-50;
            }
        }
    }

    &__form {
        border-bottom: 1px solid $color-white;
        display: flex;
        flex-direction: column;
        @include breakpoint(XS) {
            gap: .5rem;
        }

        h3{
            letter-spacing: unset;
        }
        input {
            height: 3em;
            border: 0 !important;
        }

        input[type="submit"] {
            width: auto;
            height: auto;
            border: 0;
        }
        .newsletter-form{
            .dropdown{
                background-color: $color-green;
                option{
                    color: $color-back;
                }
            }
            @include breakpoint(XS) {
                margin-top: 1rem;
            }
        }
    }

    &__logos {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-logo {
            height: 3em;
            margin-right: 1.875em;
            color: $color-white-50;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                color: $color-white-50;
            }

            svg {
                height: 3em;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    width: 3em;
                }
            }
        }
    }

    &__bottom {
        margin-top: 3.750em;
        @include breakpoint(M) {
            .container {
                padding: unset;
            }
            .col-large.items-center {
                padding-inline: 0;
            }
        }

        @include breakpoint(S) {
            margin-top: 2rem;
            .col-large.items-center {
                width: 90%;
                padding-inline: 2rem;
            }
        }

        &-left {
            position: relative;
            background-color: $color-accent;
            padding-top: 1.688em;
            padding-bottom: 1.688em;
            gap: 1.69rem;
            align-items: left;
            

            @include breakpoint (S) {
                padding-inline: 2rem;
                padding-bottom: unset;
                gap: 0.5rem;
                width: 90%;
                flex-direction: column-reverse;
            }

            &:before {
                content:'';
                background-color: $color-accent;
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                @include breakpoint (S) {
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
        }

        &-copyright {
            color: white;
            @include size-S;
            text-transform: unset;
            letter-spacing: unset;

            @include breakpoint(XS) {
                margin-top: 0.5em;
            }
        }

        &-nav {
            display: flex;
            gap: 1.69rem;

            @include breakpoint(XS) {
                margin-bottom: 1em;
                gap: unset;
                align-self: flex-start;
            }

            @include breakpoint (XS) {
                flex-wrap: wrap;
                margin-bottom: 0;
            }

            li {
                @include breakpoint (XS) {
                    margin-left: 0;
                    margin-bottom: 0.5em;
                    width: 50%;
                }

                a {
                    color: $color-white;
                    @include size-S;
                    text-transform: unset;
                    letter-spacing: unset;

                    &:hover {
                        color: $color-white-50;
                    }
                }
            }
        }

        &-social {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include breakpoint(S) {
                justify-content: flex-start;
                padding-right: unset;
                padding-left: unset;
            }

            a {
                display: flex;
                justify-content: center;
                width: 3.125em;
                height: 3.125em;
                margin-right: 1.125em;
                color: $color-white;
                border: 1px solid $color-white-50;
                border-radius: 50%;

                &:hover {
                    color: $color-white-50;
                }

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    width: 1.25em;
                    align-self: center;
                }
            }
        }

        &-siteby {
            padding-left: 1em;
            @include size-S;
            letter-spacing: unset;
            text-transform: unset;

            @include breakpoint(M) {
                align-self: end;
            }
            @include breakpoint(S) {
                padding-right: unset;
                padding-left: unset;
            }
        }
    }
}

body {
    .upc-exit-capture .upc-modal-content .upc-modal-subheading {
        font-weight: 500;
    }
    .upc-exit-capture .upc-modal-image,
    .upc-exit-capture .upc-modal-content,
    .upc-exit-capture .upc-button {
        border-radius: 0;
    }
    .upc-exit-capture .upc-button {
        font-family: "Nunito Sans",sans-serif;
        font-size: 0.688em;
        font-weight: 800;
    }
}
