//Dropdown select styling

.dropdown {
    overflow: hidden;
    position: relative;
    border: 0;
    width: 100%;
    display: block;
    cursor: pointer;
    background: transparent;

    select {
        appearance: none;
        background: none;
        border: 0;
        cursor: pointer;

        @include breakpoint(XS) {
            font-size: 16px;
        }

        &:focus {
            outline: 0;
        }

        option {
            color: $color-white-50;
        }
    }

    &:after {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3E%3Cdefs/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M11 1.5l-5 5-5-5'/%3E%3C/svg%3E");
        width: 0.75em;
        height: 0.5em;
        background-repeat: no-repeat;
    }

    &.transparent {
        background: none;
        color: #fff;
        border: 0;
        border-bottom: 1px solid #fff;

        select {
            color: #fff;
        }

        &:after {
            background-image: url('/wp-content/themes/lensbury/assets/img/dropdown-arrow-white.svg');
        }
    }

    &.label-active {
        select {
            opacity: 1;
        }
    }
}
