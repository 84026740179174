//Form Styling
 .gform-theme--framework input[type=checkbox]:where(:not(.gform-theme__disable):not(.gform-theme__disable *):not(.gform-theme__disable-framework):not(.gform-theme__disable-framework *)):checked::before {
     color: var(--color-body)
 }
.form {
    .gf_browser_chrome.gform_wrapper{
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;

        .gform_heading .gform_required_legend{
            font-style: italic;
        }
    }

    &__wrap {
        margin-right: -1em;
        margin-left:  -1em;

        .field-wrap {
            padding-right: 1em;
            padding-left:  1em;
            margin-bottom: .5rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include breakpoint(XS) {
                margin-bottom: 2em;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &.attendees{
                .wpcf7-not-valid-tip{
                    display: block !important;
                }
            }

            &--full {
                width: 100%;
            }

            &--half {
                width: 50%;
                align-content: end;

                @include breakpoint(XXS) {
                    width: 100%;
                }

                &.relative{
                    @include breakpoint(ML) {
                        width: 100%;
                    }
                }
            }

            label {
                @include size-XS;
                font-family: $font-stack-primary;
            }

            input,
            select,
            textarea {
                border-top: 0;
                border-right: 0;
                border-bottom: 1px solid $color-white;
                border-left: 0;

                .form--light & {
                    border-color: $color-green;
                }
            }

            input,
            select {
                height: 2em;
                color: $color-white-50;

                .form--light & {
                    color: $color-green;
                }
            }

            .wpcf7-select {
                // height: 5rem;
                height: 3rem;
            }

            textarea {
                // height: 6.8em;
                height: 2em;
                padding: 0.25em 0;
            }
        }
    }
}
form{
    .gform-body{
        .gform-field-label{
            color: var(--color-body);
        }
        .gfield{

            label {
                @include size-XS;
                font-family: $font-stack-primary;
                font-weight: 700;
                color: var(--color-body);
            }
            .gfield_required{
                color: var(--color-body);
            }
            .gfield_description {
                color: var(--color-body);
                opacity: .8;
            }
            .dropdown{
                position: relative;
                select{
                    padding-top: 0;
                    padding-bottom: .5rem;
                    height: auto;

                    option {
                        background-color: #193530;
                    }
                }
            }
            .ginput_container_date{
                width: 100%;
                input{
                    width: 100%;
                    color: var(--color-body);
                }
            }
            .ginput_container_multiselect{
                .dropdown{
                    select{
                        height: auto;
                        padding-bottom: .5rem;
                    }
                }
            }
            input,
            select,
            textarea {
                border-top: 0;
                border-right: 0;
                border-bottom: 1px solid $color-white;
                border-left: 0;
                background-color: transparent;
                padding-left: 0;
                padding-right: 0;
                color: var(--color-body);
                border-radius: 0;
                &:focus{
                    box-shadow: unset;
                }

                .form--light & {
                    border-color: $color-green;
                }
            }
            input,
            select {
                height: 2em;
                color: $color-white-50;
                color: var(--color-body);
                background-color: transparent;
                border-color: $color-white;
                border-radius: 0;
                &:focus{
                    box-shadow: unset;
                }

                .form--light & {
                    color: $color-green;
                }
            }
            textarea {
                padding: 0 12px;
                &:focus{
                    box-shadow: unset;
                }
            }
        }
    }
    .gform_footer{
        .gform_button {
            display: inline-block !important;
            max-width: 15.625em;
            font-family: $font-stack-secondary !important;
            height: 3.375em !important;
            border: none;
            border-radius: 4px !important;
            padding: 0 2em !important;
            background-color: $color-white !important;
            color: $color-green !important;
            font-size: 14px !important;
            font-size: .875rem !important;
            line-height: 1.35rem !important;
            text-transform: uppercase !important;
            font-weight: 800 !important;
            letter-spacing: .125em !important;
    
            &:hover {
                color: $color-white;
            }
    
            @include breakpoint(XS) {
                max-width: none;
                width: 100%;
            }
        }
    }
}

//Label Styling

label {
    font-weight: 800;
    @extend %transition;
}

//Standard Input Styling

input,
textarea,
select {
    //Resets
    display: block;
    box-shadow: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 2;

    background-color: transparent;
    color: $color-white;

    font-family: $font-stack-primary;
    font-size: 1rem;

    padding-bottom: 0;
    width: 100%;

    border: none;
    border-radius: 0;

    @extend %transition;

    //Focus State
    &:focus {
        outline: 0;
        border-color: $color-accent !important;
    }

    @include placeholder {
        color: $color-white;
        opacity: 0.5;

        .form--light & {
            color: $color-green;
        }
    }

    &[type=text] {
        appearance: none;
    }

    &.wpcf7-not-valid {
        border-color: $color-error !important;
    }

    @include breakpoint(XS) {
        font-size: 16px;
    }

    //Reset margin-bottom for last element
    &:last-child,
    &.no-margin {
        margin-bottom: 0;
    }
}

// Input Submit

input[type="submit"] {
    padding: 0;
    font-family: $font-stack-secondary;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    cursor: pointer;
    @include size-XS;
}

.input-wrap {
    position: relative;

    label {
        @extend %transition;
        z-index: 1;
    }

    input,
    textarea {
        border-bottom: 1px solid $color-body;
        border-bottom-color: var(--color-body);
        padding: 1.5em 1em 0.5em 0;
        margin: 0;

        &:focus {
            outline: 0;
            border-bottom: 1px solid $color-primary;
            border-bottom-color: var(--color-accent1);

            + label {
                color: $color-primary;
                color: var(--color-accent1);
                top: 0.25rem;
            }
        }
    }

    textarea {
        height: 10rem;
    }

    &.label-active {
        label {
            color: $color-primary;
            top: 0;
        }
    }

    &.datepicker {
        cursor: pointer;
        border-radius: 0;

        input {
            cursor: pointer;
        }
    }

    &.has-icon {
        input {
            padding-right: 3em;
        }

        .icon {
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 1.5em;
        }
    }
}

// CTF7 Plugin overides

.wpcf7 {

    input[type="submit"] {
        display: inline-block !important;
        max-width: 15.625em;
        height: 3.375em;
        border: none;
        border-radius: 4px;
        padding: 0 2em !important;
        background-color: $color-white;
        color: $color-green;
        @include size-S;

        &:hover {
            background-color: $color-accent;
            color: $color-white;
        }

        @include breakpoint(XS) {
            max-width: none;
            width: 100%;
        }
    }
}

div.wpcf7-response-output {
    margin: 2em 0 0 0 !important;
    padding: 1em !important;
    border: 1px solid $color-error;
    clear: both !important;
    color: $color-white;
    position: relative !important;
    text-align: center !important;

    &.wpcf7-validation-errors {
        border: 1px solid $color-error;
    }
}

div.wpcf7-mail-sent-ok {
    border: 2px solid $color-success !important;
}

.ajax-loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 10px !important;
    width: 20px !important;
    height: 20px !important;
    background-image: url('/wp-content/themes/lensbury/assets/img/spinner.svg') !important;

    @include breakpoint(XS) {
        position: relative !important;
        top: auto !important;
        display: none !important;
        margin: 20px auto 0 auto !important;
        transform: none !important;

        &.is-active {
            display: block !important;
        }
    }
}

.wpcf7-not-valid-tip {
    display: none !important;
}

.search-dropdown {
    position: relative;

    .dropdown {
        padding-left: 2rem;

        &:after {
            display: none;
        }
    }
    svg {
        position: absolute;
        left: 0.5rem;
        top: calc(50% - 16px);
    }
}
@import "dropdowns";
@import "checkboxes";
