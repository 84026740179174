
/* Object Fit
   ========================================================================== */

.object-fit {
    object-fit: cover;
    font-family: 'object-fit: cover;';

    &.contain {
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}

/* Images
   ========================================================================== */

.image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.full-width-img {
    width: 100%;
    height: auto;
    margin-bottom: 2em;
}

img,
.wp-caption {
    max-width: 100%;
    height: auto;

    &.align-center,
    &.aligncenter {
        margin-left: auto;
        margin-right: auto;
    }
    &.alignleft {
        float: left;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    &.alignright {
        float: right;
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
}

/* Background Repeat
   ========================================================================== */

.bg-repeat {
    background-repeat: repeat;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat-x {
    background-repeat: repeat-x;
}

.bg-repeat-y {
    background-repeat: repeat-y;
}

.bg-repeat-round {
    background-repeat: round;
}

.bg-repeat-space {
    background-repeat: space;
}


/* Background Size
   ========================================================================== */

.bg-auto {
    background-size: auto;
}

.bg-cover {
    background-size: cover;
}

.bg-contain	{
    background-size: contain;
}

/* Position
   ========================================================================== */

.sticky {
    position: sticky;
    top: 0;

    @include breakpoint (XS) {
        position: relative;
    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

/* Hide
   ========================================================================== */

.hide-mobile {
    @include breakpoint(XS) {
        display: none !important;
    }
}

.hide-tablet {
    @include breakpoint(S) {
        display: none !important;
    }
}

.show-tablet {
    display: none;

    @include breakpoint(S) {
        display: block !important;
    }
}

.hide-l {
    @include breakpoint(L) {
        display: none !important;
    }
}

.hide-desktop {
    display: none !important;

    @include breakpoint(XS) {
        display: block !important;
    }
    &.flex {
        @include breakpoint(XS) {
            display: flex !important;
        }
    }
}

/* Scroll
   ========================================================================== */

@keyframes scrollPattern {
    0% {
      transform: translate3d(0,0,0);
    }
    100% {
      transform: translate3d(0,442px,0);
    }
  }

.scrolling {
    height: 1000%;
    animation: scrollPattern 60s linear infinite;
}


/* Flex
   ========================================================================== */

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row
}

.flex-row-reverse {
    flex-direction: row-reverse
}

.flex-col {
    flex-direction: column
}

.flex-col-reverse {
    flex-direction: column-reverse
}

.flex-wrap {
    flex-wrap: wrap
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.items-baseline {
    align-items: baseline
}

.items-stretch {
    align-items: stretch
}

.self-auto {
    align-self: auto
}

.self-start {
    align-self: flex-start
}

.self-end {
    align-self: flex-end
}

.self-center {
    align-self: center
}

.self-stretch {
    align-self: stretch
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-around {
    justify-content: space-around
}

.content-center {
    align-content: center
}

.content-start {
    align-content: flex-start
}

.content-end {
    align-content: flex-end
}

.content-between {
    align-content: space-between
}

.content-around {
    align-content: space-around
}

.flex-1 {
    flex: 1 1 0%
}

.flex-auto {
    flex: 1 1 auto
}

.flex-initial {
    flex: 0 1 auto
}

.flex-none {
    flex: none
}

.flex-grow-0 {
    flex-grow: 0
}

.flex-grow {
    flex-grow: 1
}

.flex-shrink-0 {
    flex-shrink: 0
}

.flex-shrink {
    flex-shrink: 1
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-first {
    order: -1
}

.order-last {
    order: 999
}

.order-none,
.order-normal {
    order: 0
}

/* md */

@include breakpoint(M) {

    .md\:flex {
        display: flex;
    }

    .md\:justify-end {
        justify-content: flex-end;
    }

    .md\:justify-center {
        justify-content: center;
    }

    .md\:flex-wrap {
        flex-wrap: wrap
    }
    .md\:order-last {
        order: 999;
    }
}

/* sm */

@include breakpoint(S) {

    .sm\:flex {
        display: flex;
    }

    .sm\:flex-auto {
        flex: 1 1 auto
    }

    .sm\:flex-wrap {
        flex-wrap: wrap
    }

    .sm\:justify-end {
        justify-content: flex-end;
    }

    .sm\:justify-center {
        justify-content: center;
    }

    // orders

    .sm\:order-1 {
        order: 1
    }

    .sm\:order-2 {
        order: 2
    }

    .sm\:order-3 {
        order: 3
    }

    .sm\:order-4 {
        order: 4
    }

    .sm\:order-5 {
        order: 5
    }

    .sm\:order-6 {
        order: 6
    }

    .sm\:order-first {
        order: -1
    }

    .sm\:order-last {
        order: 999;
        z-index: 1;
    }

    .sm\:order-none,
    .sm\:order-normal {
        order: 0
    }
}

/* xs */

@include breakpoint(XS) {

    .xs\:flex {
        display: flex;
    }

    .xs\:flex-auto {
        flex: 1 1 auto
    }

    .xs\:justify-end {
        justify-content: flex-end;
    }

    .xs\:justify-center {
        justify-content: center;
    }

    .xs\:flex-wrap {
        flex-wrap: wrap
    }

    // orders

    .xs\:order-1 {
        order: 1
    }

    .xs\:order-2 {
        order: 2
    }

    .xs\:order-3 {
        order: 3
    }

    .xs\:order-4 {
        order: 4
    }

    .xs\:order-5 {
        order: 5
    }

    .xs\:order-6 {
        order: 6
    }

    .xs\:order-first {
        order: -1
    }

    .xs\:order-last {
        order: 999
    }

    .xs\:order-none,
    .xs\:order-normal {
        order: 0
    }

}

/* xxs */

@include breakpoint(XXS) {

    .xxs\:flex {
        display: flex;
    }

    .xxs\:flex {
        display: flex;
    }

    .xxs\:justify-end {
        justify-content: flex-end;
    }

    .xxs\:flex-wrap {
        flex-wrap: wrap
    }

    // orders

    .xxs\:order-1 {
        order: 1
    }

    .xxs\:order-2 {
        order: 2
    }

    .xxs\:order-3 {
        order: 3
    }

    .xxs\:order-4 {
        order: 4
    }

    .xxs\:order-5 {
        order: 5
    }

    .xxs\:order-6 {
        order: 6
    }

    .xxs\:order-first {
        order: -1
    }

    .xxs\:order-last {
        order: 999
    }

    .xxs\:order-none,
    .xxs\:order-normal {
        order: 0
    }

}

/* List Styles
   ========================================================================== */

ul.list-reset {
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }
}

/* Background Colours
   ========================================================================== */

.bg {

    &-white {
        background-color: $color-white;

        &-50 {
            background-color: $color-white-50;
        }

        &-25 {
            background-color: $color-white-25;
        }

        &-12 {
            background-color: $color-white-12;
        }
    }

    &-green {
        background-color: $color-green;

        &-50 {
            background-color: $color-green-50;
        }

        &-25 {
            background-color: $color-green-25;
        }

        &-12 {
            background-color: $color-green-12;
        }
    }

    &-brown {
        background-color: $color-accent;

        &-50 {
            background-color: $color-accent-50;
        }

        &-25 {
            background-color: $color-accent-25;
        }

        &-12 {
            background-color: $color-accent-12;
        }
    }

    &-blue {
        background-color: $color-blue;
    }
}

/* Text Colours
   ========================================================================== */

.text {

    li {
        font-size: 1em;
        line-height: 1;
        margin-bottom: 1rem;
        color: inherit;
        font-family: $font-stack-primary;
        padding-left: 1rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0.375rem;
            height: 0.375rem;
            background-color: $color-body;
        }
    }

    &-white {
        color: $color-white;

        &-off {
            color: $color-white-off;
        }

        &-50 {
            color: $color-white-50;
        }

        &-25 {
            color: $color-white-25;
        }

        &-12 {
            color: $color-white-12;
        }

        a {
            color: $color-accent;
        }
    }

    &-grey {
        color: $color-grey;
    }

    &-green {
        color: $color-green;

        &-50 {
            color: $color-green-50;
        }

        &-25 {
            color: $color-green-25;
        }

        &-12 {
            color: $color-green-12;
        }
    }

    &-brown {
        color: $color-accent;

        &-50 {
            color: $color-accent-50;
        }

        &-25 {
            color: $color-accent-25;
        }

        &-12 {
            color: $color-accent-12;
        }
    }

    &-blue {
        color: $color-blue;
    }

}

/* Text Align
   ========================================================================== */

.text {

    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

/* md */

@include breakpoint(M) {

    .md\:text {

        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

/* sm */

@include breakpoint(S) {

    .sm\:text {

        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

/* xs */

@include breakpoint(XS) {

    .xs\:text {

        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

/* xxs */

@include breakpoint(XXS) {

    .xxs\:text {

        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

/* Margins
   ========================================================================== */

/* right left */

   .mx-auto {
       margin-right: auto;
       margin-left: auto;
   }

/* bottom */

.mb {

    &-0	{
        margin-bottom: 0;
    }

    &-1	{
        margin-bottom: 0.25rem;
    }

    &-2	{
        margin-bottom: 0.5rem;
    }

    &-3	{
        margin-bottom: 0.75rem;
    }

    &-4	{
        margin-bottom: 1rem;
    }

    &-5	{
        margin-bottom: 1.25rem;
    }

    &-6	{
        margin-bottom: 1.5rem;
    }

    &-8	{
        margin-bottom: 2rem;
    }

    &-10 {
       margin-bottom: 2.5rem;
    }

    &-12 {
       margin-bottom: 3rem;
    }

    &-16 {
       margin-bottom: 4rem;
    }

    &-20 {
       margin-bottom: 5rem;
    }

    &-24 {
       margin-bottom: 6rem;
    }

    &-32 {
       margin-bottom: 8rem;
    }

    &-40 {
       margin-bottom: 10rem;
    }

    &-48 {
       margin-bottom: 12rem;
    }

    &-56 {
       margin-bottom: 14rem;
    }

    &-64 {
       margin-bottom: 16rem;
    }
}

/* md */

@include breakpoint(M) {

    /* bottom */

    .md\:mb {

        &-0	{
            margin-bottom: 0;
        }

        &-1	{
            margin-bottom: 0.25rem;
        }

        &-2	{
            margin-bottom: 0.5rem;
        }

        &-3	{
            margin-bottom: 0.75rem;
        }

        &-4	{
            margin-bottom: 1rem;
        }

        &-5	{
            margin-bottom: 1.25rem;
        }

        &-6	{
            margin-bottom: 1.5rem;
        }

        &-8	{
            margin-bottom: 2rem;
        }

        &-10 {
           margin-bottom: 2.5rem;
        }

        &-12 {
           margin-bottom: 3rem;
        }

        &-16 {
           margin-bottom: 4rem;
        }

        &-20 {
           margin-bottom: 5rem;
        }

        &-24 {
           margin-bottom: 6rem;
        }

        &-32 {
           margin-bottom: 8rem;
        }

        &-40 {
           margin-bottom: 10rem;
        }

        &-48 {
           margin-bottom: 12rem;
        }

        &-56 {
           margin-bottom: 14rem;
        }

        &-64 {
           margin-bottom: 16rem;
        }
    }
}

/* sm */

@include breakpoint(S) {

    /* bottom */

    .sm\:mb {

        &-0	{
            margin-bottom: 0;
        }

        &-1	{
            margin-bottom: 0.25rem;
        }

        &-2	{
            margin-bottom: 0.5rem;
        }

        &-3	{
            margin-bottom: 0.75rem;
        }

        &-4	{
            margin-bottom: 1rem;
        }

        &-5	{
            margin-bottom: 1.25rem;
        }

        &-6	{
            margin-bottom: 1.5rem;
        }

        &-8	{
            margin-bottom: 2rem;
        }

        &-10 {
           margin-bottom: 2.5rem;
        }

        &-12 {
           margin-bottom: 3rem;
        }

        &-16 {
           margin-bottom: 4rem;
        }

        &-20 {
           margin-bottom: 5rem;
        }

        &-24 {
           margin-bottom: 6rem;
        }

        &-32 {
           margin-bottom: 8rem;
        }

        &-40 {
           margin-bottom: 10rem;
        }

        &-48 {
           margin-bottom: 12rem;
        }

        &-56 {
           margin-bottom: 14rem;
        }

        &-64 {
           margin-bottom: 16rem;
        }
    }
}

/* xs */

@include breakpoint(XS) {

    /* bottom */

    .xs\:mb {

        &-0	{
            margin-bottom: 0;
        }

        &-1	{
            margin-bottom: 0.25rem;
        }

        &-2	{
            margin-bottom: 0.5rem;
        }

        &-3	{
            margin-bottom: 0.75rem;
        }

        &-4	{
            margin-bottom: 1rem;
        }

        &-5	{
            margin-bottom: 1.25rem;
        }

        &-6	{
            margin-bottom: 1.5rem;
        }

        &-8	{
            margin-bottom: 2rem;
        }

        &-10 {
           margin-bottom: 2.5rem;
        }

        &-12 {
           margin-bottom: 3rem;
        }

        &-16 {
           margin-bottom: 4rem;
        }

        &-20 {
           margin-bottom: 5rem;
        }

        &-24 {
           margin-bottom: 6rem;
        }

        &-32 {
           margin-bottom: 8rem;
        }

        &-40 {
           margin-bottom: 10rem;
        }

        &-48 {
           margin-bottom: 12rem;
        }

        &-56 {
           margin-bottom: 14rem;
        }

        &-64 {
           margin-bottom: 16rem;
        }
    }
}

/* Padding
   ========================================================================== */

   /* top */

   .pt {

       &-0	{
           padding-top: 0;
       }

       &-1	{
           padding-top: 0.25rem;
       }

       &-2	{
           padding-top: 0.5rem;
       }

       &-3	{
           padding-top: 0.75rem;
       }

       &-4	{
           padding-top: 1rem;
       }

       &-5	{
           padding-top: 1.25rem;
       }

       &-6	{
           padding-top: 1.5rem;
       }

       &-8	{
           padding-top: 2rem;
       }

       &-10 {
          padding-top: 2.5rem;
       }

       &-12 {
          padding-top: 3rem;
       }

       &-16 {
          padding-top: 4rem;
       }

       &-20 {
          padding-top: 5rem;
       }

       &-24 {
          padding-top: 6rem;
       }

       &-32 {
          padding-top: 8rem;
       }

       &-40 {
          padding-top: 10rem;
       }

       &-48 {
          padding-top: 12rem;
       }

       &-56 {
          padding-top: 14rem;
       }

       &-64 {
          padding-top: 16rem;
       }
   }

   /* md */

   @include breakpoint(M) {

       /* top */

       .md\:pt {

           &-0	{
               padding-top: 0;
           }

           &-1	{
               padding-top: 0.25rem;
           }

           &-2	{
               padding-top: 0.5rem;
           }

           &-3	{
               padding-top: 0.75rem;
           }

           &-4	{
               padding-top: 1rem;
           }

           &-5	{
               padding-top: 1.25rem;
           }

           &-6	{
               padding-top: 1.5rem;
           }

           &-8	{
               padding-top: 2rem;
           }

           &-10 {
              padding-top: 2.5rem;
           }

           &-12 {
              padding-top: 3rem;
           }

           &-16 {
              padding-top: 4rem;
           }

           &-20 {
              padding-top: 5rem;
           }

           &-24 {
              padding-top: 6rem;
           }

           &-32 {
              padding-top: 8rem;
           }

           &-40 {
              padding-top: 10rem;
           }

           &-48 {
              padding-top: 12rem;
           }

           &-56 {
              padding-top: 14rem;
           }

           &-64 {
              padding-top: 16rem;
           }
       }
   }

   /* sm */

   @include breakpoint(S) {

       /* top */

       .sm\:pt {

           &-0	{
               padding-top: 0;
           }

           &-1	{
               padding-top: 0.25rem;
           }

           &-2	{
               padding-top: 0.5rem;
           }

           &-3	{
               padding-top: 0.75rem;
           }

           &-4	{
               padding-top: 1rem;
           }

           &-5	{
               padding-top: 1.25rem;
           }

           &-6	{
               padding-top: 1.5rem;
           }

           &-8	{
               padding-top: 2rem;
           }

           &-10 {
              padding-top: 2.5rem;
           }

           &-12 {
              padding-top: 3rem;
           }

           &-16 {
              padding-top: 4rem;
           }

           &-20 {
              padding-top: 5rem;
           }

           &-24 {
              padding-top: 6rem;
           }

           &-32 {
              padding-top: 8rem;
           }

           &-40 {
              padding-top: 10rem;
           }

           &-48 {
              padding-top: 12rem;
           }

           &-56 {
              padding-top: 14rem;
           }

           &-64 {
              padding-top: 16rem;
           }
       }
   }

   /* xs */

   @include breakpoint(XS) {

       /* top */

       .xs\:pt {

           &-0	{
               padding-top: 0;
           }

           &-1	{
               padding-top: 0.25rem;
           }

           &-2	{
               padding-top: 0.5rem;
           }

           &-3	{
               padding-top: 0.75rem;
           }

           &-4	{
               padding-top: 1rem;
           }

           &-5	{
               padding-top: 1.25rem;
           }

           &-6	{
               padding-top: 1.5rem;
           }

           &-8	{
               padding-top: 2rem;
           }

           &-10 {
              padding-top: 2.5rem;
           }

           &-12 {
              padding-top: 3rem;
           }

           &-16 {
              padding-top: 4rem;
           }

           &-20 {
              padding-top: 5rem;
           }

           &-24 {
              padding-top: 6rem;
           }

           &-32 {
              padding-top: 8rem;
           }

           &-40 {
              padding-top: 10rem;
           }

           &-48 {
              padding-top: 12rem;
           }

           &-56 {
              padding-top: 14rem;
           }

           &-64 {
              padding-top: 16rem;
           }
       }
   }

   /* bottom */

   .pb {

       &-0	{
           padding-bottom: 0;
       }

       &-1	{
           padding-bottom: 0.25rem;
       }

       &-2	{
           padding-bottom: 0.5rem;
       }

       &-3	{
           padding-bottom: 0.75rem;
       }

       &-4	{
           padding-bottom: 1rem;
       }

       &-5	{
           padding-bottom: 1.25rem;
       }

       &-6	{
           padding-bottom: 1.5rem;
       }

       &-8	{
           padding-bottom: 2rem;
       }

       &-10 {
          padding-bottom: 2.5rem;
       }

       &-12 {
          padding-bottom: 3rem;
       }

       &-16 {
          padding-bottom: 4rem;
       }

       &-20 {
          padding-bottom: 5rem;
       }

       &-24 {
          padding-bottom: 6rem;
       }

       &-32 {
          padding-bottom: 8rem;
       }

       &-40 {
          padding-bottom: 10rem;
       }

       &-48 {
          padding-bottom: 12rem;
       }

       &-56 {
          padding-bottom: 14rem;
       }

       &-64 {
          padding-bottom: 16rem;
       }
   }

   /* md */

   @include breakpoint(M) {

       /* bottom */

       .md\:pb {

           &-0	{
               padding-bottom: 0;
           }

           &-1	{
               padding-bottom: 0.25rem;
           }

           &-2	{
               padding-bottom: 0.5rem;
           }

           &-3	{
               padding-bottom: 0.75rem;
           }

           &-4	{
               padding-bottom: 1rem;
           }

           &-5	{
               padding-bottom: 1.25rem;
           }

           &-6	{
               padding-bottom: 1.5rem;
           }

           &-8	{
               padding-bottom: 2rem;
           }

           &-10 {
              padding-bottom: 2.5rem;
           }

           &-12 {
              padding-bottom: 3rem;
           }

           &-16 {
              padding-bottom: 4rem;
           }

           &-20 {
              padding-bottom: 5rem;
           }

           &-24 {
              padding-bottom: 6rem;
           }

           &-32 {
              padding-bottom: 8rem;
           }

           &-40 {
              padding-bottom: 10rem;
           }

           &-48 {
              padding-bottom: 12rem;
           }

           &-56 {
              padding-bottom: 14rem;
           }

           &-64 {
              padding-bottom: 16rem;
           }
       }
   }

   /* sm */

   @include breakpoint(S) {

       /* bottom */

       .sm\:pb {

           &-0	{
               padding-bottom: 0;
           }

           &-1	{
               padding-bottom: 0.25rem;
           }

           &-2	{
               padding-bottom: 0.5rem;
           }

           &-3	{
               padding-bottom: 0.75rem;
           }

           &-4	{
               padding-bottom: 1rem;
           }

           &-5	{
               padding-bottom: 1.25rem;
           }

           &-6	{
               padding-bottom: 1.5rem;
           }

           &-8	{
               padding-bottom: 2rem;
           }

           &-10 {
              padding-bottom: 2.5rem;
           }

           &-12 {
              padding-bottom: 3rem;
           }

           &-16 {
              padding-bottom: 4rem;
           }

           &-20 {
              padding-bottom: 5rem;
           }

           &-24 {
              padding-bottom: 6rem;
           }

           &-32 {
              padding-bottom: 8rem;
           }

           &-40 {
              padding-bottom: 10rem;
           }

           &-48 {
              padding-bottom: 12rem;
           }

           &-56 {
              padding-bottom: 14rem;
           }

           &-64 {
              padding-bottom: 16rem;
           }
       }
   }

   /* xs */

   @include breakpoint(XS) {

       /* bottom */

       .xs\:pb {

           &-0	{
               padding-bottom: 0;
           }

           &-1	{
               padding-bottom: 0.25rem;
           }

           &-2	{
               padding-bottom: 0.5rem;
           }

           &-3	{
               padding-bottom: 0.75rem;
           }

           &-4	{
               padding-bottom: 1rem;
           }

           &-5	{
               padding-bottom: 1.25rem;
           }

           &-6	{
               padding-bottom: 1.5rem;
           }

           &-8	{
               padding-bottom: 2rem;
           }

           &-10 {
              padding-bottom: 2.5rem;
           }

           &-12 {
              padding-bottom: 3rem;
           }

           &-16 {
              padding-bottom: 4rem;
           }

           &-20 {
              padding-bottom: 5rem;
           }

           &-24 {
              padding-bottom: 6rem;
           }

           &-32 {
              padding-bottom: 8rem;
           }

           &-40 {
              padding-bottom: 10rem;
           }

           &-48 {
              padding-bottom: 12rem;
           }

           &-56 {
              padding-bottom: 14rem;
           }

           &-64 {
              padding-bottom: 16rem;
           }
       }
   }


/* Heights
   ========================================================================== */

.h-40 {
    height: 40px;
}

.h-full {
    height: 100%;
}

/* Text Transform
   ========================================================================== */

.uppercase {
    text-transform: uppercase;
}

/* Font size
   ========================================================================== */

.size-xs {
    @include size-XS;
}

.size-s {
    @include size-S;
}

.size-m {
    @include size-M;
}

.size-l {
    @include size-L;
}

.size-xl {
    @include size-XL;
}

.size-2xl {
    @include size-2XL;
}

.size-3xl {
    @include size-3XL;
}

.size-full {
    font-size: 100%;
}

/* Font Weights
   ========================================================================== */

.font-hairline {
    font-weight: 100;
}

.font-thin {
    font-weight: 200;
}

.font-light	{
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold	{
    font-weight: 800;
}

.font-black	{
    font-weight: 900;
}

/* Font Families
   ========================================================================== */

.font-primary {
   font-family: $font-stack-primary;
}

.font-secondary {
   font-family: $font-stack-narrow;
   @include size-S
}

/* Box Shadow
   ========================================================================== */

.shadow {
    box-shadow: 3px 9px 12px rgba(24, 51, 30, 0.125);
}

/* Display
   ========================================================================== */

.hidden	{
    display: none;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline	{
    display: inline;
}

/* md */

@include breakpoint(M) {

    .md\:hidden	{
        display: none;
    }

    .md\:block {
        display: block;
    }

    .md\:inline-block {
        display: inline-block;
    }

    .md\:inline	{
        display: inline;
    }

}

/* sm */

@include breakpoint(S) {

    .sm\:hidden	{
        display: none;
    }

    .sm\:block {
        display: block;
    }

    .sm\:inline-block {
        display: inline-block;
    }

    .sm\:inline	{
        display: inline;
    }

}

/* xs */

@include breakpoint(XS) {

    .xs\:hidden	{
        display: none;
    }

    .xs\:block {
        display: block;
    }

    .xs\:inline-block {
        display: inline-block;
    }

    .xs\:inline	{
        display: inline;
    }

}

/* Spacing
   ========================================================================== */

.spacing {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include breakpoint(XS) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &--top {
        padding-top: 5rem;

        @include breakpoint(XS) {
            padding-top: 2rem;
        }
    }

    &--bottom {
        padding-bottom: 5rem;

        @include breakpoint(XS) {
            padding-bottom: 2rem;
        }
    }

    &--reset-mob {
        @include breakpoint(XS) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

/* Filters
   ========================================================================== */

.blur {
    filter: blur(10px);
}

/* Letter Spacing
   ========================================================================== */

.tracking-tighter {
    letter-spacing: -0.05em;
}

.tracking-tight {
    letter-spacing: -0.025em;
}

.tracking-normal {
    letter-spacing: 0;
}

.tracking-wide {
    letter-spacing: 0.125em;
}

.tracking-wider	 {
    letter-spacing: 0.2em;
}

.tracking-widest {
    letter-spacing: 0.3em;
}

/* Max-Width
   ========================================================================== */

.max-w-none	{
    max-width: none;
}

.max-w-full	{
    max-width: 100%;
}

.max-w-content {
    max-width: max-content;
}

/* sm */

@include breakpoint(S) {

    .sm\:max-w-none	{
        max-width: none;
    }

    .sm\:max-w-full	{
        max-width: 100%;
    }

    .sm\:max-w-content {
        max-width: max-content;
    }
}

/* Overflow
   ========================================================================== */

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

/* List Style Type
   ========================================================================== */

.list-none	{
    list-style-type: none !important;
}

.list-disc	{
    list-style-type: disc !important;
}

.list-decimal {
    list-style-type: decimal !important;
}


/* event link styles
   ========================================================================== */
.event-modal-content__wrap, .event-modal-content-single__wrap{
    a:not(.button){
        color: $color-green;
        &:hover{
            color: $color-accent;
        }
    }
    ul{
        margin-bottom: 1rem;
    }
}

/* event date container
   ========================================================================== */
.date-range-container{
    border: 2px solid;
    display: inline-flex;
    padding: 0.2rem 0.5rem;
    gap: 0.25rem;
    align-items: normal;
    text-transform: capitalize;
    @include size-XS;
    font-family: $font-stack-secondary;
    font-weight: 600;
    line-height: unset;
}

article{
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
    max-width: 49rem;
    margin: auto;

    h3{
       // margin-bottom: unset;
    }
}