.error404 {
    padding-top: 12em;

    @include breakpoint(S) {
        padding-top: 7em;
    }
}

body {
    &.page-template-page-gallery-front {

        @include breakpoint (XS) {
            padding-bottom: 5.5em;
        }
    }
}

.builder-elements {
    width: 100%;
    position: relative;

    section {
        &.has-bg {
            &:last-child {
                margin-bottom: -5rem;
                @include breakpoint(XS) {
                    margin-bottom: -2rem;
                }
            }
            +.has-bg {
                margin-top: -5rem;
                @include breakpoint(XS) {
                    margin-top: -2rem;
                }
            }
        }
    }
}

.content {
    p {

        &:last-child {
            margin-bottom: 0;
        }
    }
}
