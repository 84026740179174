//Font Sizes

@mixin size-XS() {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: $unit * 2.25;

    @include breakpoint(M) {
      font-size: 10px;
      font-size: 0.625rem;
      line-height: $unit * 2;
    }
}

@mixin size-S() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: $unit * 2.7;

    @include breakpoint(S) {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: $unit * 2.7;
    }
}

@mixin size-M() {
    font-size: 16px;
    font-size: 1rem;
    line-height: $unit * 3;

    @include breakpoint(M) {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: $unit * 3;
    }
}

  @mixin size-L() {
    font-size: 21px;
    font-size: 1.313rem;
    line-height: $unit * 3.9;

    @include breakpoint(S) {
        font-size: 17px;
        font-size: calc(15rem / 14);
        line-height: $unit * 3.5;
    }
}

@mixin size-XL() {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: $unit * 4.5;

    @include breakpoint(S) {
        font-size: 21px;
        font-size: 1.3125rem;
        line-height: $unit * 4;
    }
}

@mixin size-2XL() {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: $unit * 6;

    @include breakpoint(S) {
        font-size: 26px;
        font-size: calc(28rem / 14);
        line-height: $unit * 4.5;
    }
}

@mixin size-3XL() {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: $unit * 8;

    @include breakpoint(S) {
        font-size: 32px;
        font-size: calc(28rem / 14);
        line-height: $unit * 5;

    }
}