// //Brand Colours
// $color-white: #FFFFFF;
// $color-white-off: #F7F8F7;
// $color-white-50: rgba($color-white, 0.5);
// $color-white-25: rgba($color-white, 0.25);
// $color-white-12: rgba($color-white, 0.12);

// $color-grey: #EFF1EF;

// $color-back: #000000;
// $color-back-60: rgba($color-back, 0.6);

// $color-green: #18332E;
// $color-green-50: rgba($color-green, 0.5);
// $color-green-25: rgba($color-green, 0.25);
// $color-green-12: rgba($color-green, 0.12);

// $color-accent: #8F5C1D;
// $color-accent-50: rgba($color-accent, 0.5);
// $color-accent-25: rgba($color-accent, 0.25);
// $color-accent-12: rgba($color-accent, 0.12);

// $color-blue: #1845A3;

// // Colors
// $color-body: $color-green;
// $color-primary: $color-white;

// // Active
// $color-active: $color-green;

// // Warnings
// $color-info: #8F5C1D;
// $color-success: #519872;
// $color-warning: #D5AC4E;
// $color-error: #C84C09;

//new definition

// $color-body: #193530;
// $color-background: #F9FCFA;
// $color-background-alt: #FFFFFF;
// $color-accent-primary: #90C89B;
// // $color-dark-accent-primary: #A28F77;

// $color-info: $color-accent-primary;
// $color-success: #519872;
// $color-warning: #D5AC4E;
// $color-error: #C84C09;



//Brand Colours
$color-white: #FFFFFF;
$color-white-off: #F9FCFA;
$color-white-50: rgba($color-white, 0.5);
$color-white-25: rgba($color-white, 0.25);
$color-white-12: rgba($color-white, 0.12);

$color-grey: #EFF1EF;

$color-back: #000000;
$color-back-60: rgba($color-back, 0.6);

$color-green: #193530;
$color-green-50: rgba($color-green, 0.5);
$color-green-25: rgba($color-green, 0.25);
$color-green-12: rgba($color-green, 0.12);

$color-accent: #90C89B;
$color-accent-50: rgba($color-accent, 0.5);
$color-accent-25: rgba($color-accent, 0.25);
$color-accent-12: rgba($color-accent, 0.12);

$color-blue: #193530;

// Colors
$color-body: $color-green;
$color-primary: $color-white;

// Active
$color-active: $color-green;

// Warnings
$color-info: #90C89B;
$color-success: #519872;
$color-warning: #D5AC4E;
$color-error: #C84C09;