.accordion {
    margin-bottom: 2rem;

    &.border-top {
        border-top: 1px solid rgba($color-body, 0.25);
    }
    .accordion-title {
        cursor: pointer;
        position: relative;
        @extend %transition;

        &:focus {
            outline: 0;
        }
        &.has-icon {
            padding-right: 3rem;

            @include breakpoint(XS) {
                padding-right: 3.5rem;
            }
        }
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border-radius: 100%;
            border: 1px solid $color-green;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @extend %transition;

            path,
            svg {
                @extend %transition;
            }
        }
        h3,
        h4,
        h5,
        p {
            font-style: normal;
            color: $color-body;
            margin: 0;
            padding: 1.5rem 0;
            @extend %transition;
        }
        &.inner-padding {
            h3,
            h4,
            p {
                padding: 1.5rem 5rem 1.5rem 1.5rem;
            }
            .icon {
                right: 1.5rem;
            }
        }
        &:hover {
            color: $color-accent;

            h3,
            h4,
            p {
                color: $color-accent;
            }
        }
        &.open {
            h3,
            h4,
            p {
                color: $color-accent;
            }
            .icon {
                border: 1px solid $color-accent;
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .accordion-content {
        display: none;
        padding: 0 0 1.5em 0;
        strong{
            font-weight: bold!important;
        }
        ul{
            list-style: disc!important;
            margin-left: 2.5rem;
        }

        &.inner-accordion {
            padding: 1rem 3rem 1.5em 3rem;
        }
        p {
            max-width: none;
        }
        p:last-child,
        ul:last-child {
            margin-bottom: 0;
        }
    }
    .accordion-group {
        border-bottom: 1px solid rgba($color-body, 0.25);

        &.open {
            border-bottom: 1px solid $color-accent;
        }
    }
    &.featured {
        max-width: 56rem;
        margin: 0 auto;
        background: #fff;

        .accordion-title {
            border-bottom: 1px solid #fff;
            background: $color-body;
            padding-left: 2rem;
            padding-right: 2rem;
            text-align: left;

            @include breakpoint(XS) {
                padding-right: 4rem;
            }
            h3,
            h4,
            h5,
            p {
                color: #fff;
            }
            &:before {
                content: "";
                background: #fff;
                position: absolute;
                height: 2px;
                width: 1.125rem;
                right: 1.75rem;
                top: calc(50% - 1px);
            }
            &:after {
                content: "";
                background: #fff;
                position: absolute;
                width: 2px;
                height: 1.125rem;
                right: calc(2.3125rem - 1px);
                top: calc(50% - 0.5625rem);
                @extend %transition;
            }
            &:hover {
                h3,
                h4,
                h5,
                p {
                    color: $color-body;
                }
            }
            &.open {
                &:after {
                    opacity: 0;
                }
            }
        }
        .accordion-group {
            border-bottom: 0;
        }
        .accordion-content {
            padding: 2rem;
            text-align: left;
            border-left: 1px solid $color-body;
            border-right: 1px solid $color-body;
            border-bottom: 1px solid $color-body;

            

            @include breakpoint(XS) {
                padding: 2rem;
            }
        }
    }
}
