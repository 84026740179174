//   * CSS Animations are Defined here – feel free to use them as appropriate!
//     This uses Compass mixins to handle all the vendor prefixes – when you want to use them in your CSS, use the following syntax below:
//
//     .item{
//     @include animation(YOUR-ANIMATION-HERE TIME INFINITE-OR-ONCE-ONLY EASING);
//     @include transform-origin(50% 50%);
//     }

// *** 1. Basic Fades *** //

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translateX(-1em);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translateX(1em);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(1em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translateY(-1em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bob {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.5em);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes throb {
    0% {
        opacity: 1;
    }
    45% {
        opacity: 0.5;
    }
    55% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-in-up {
    0% {
        opacity: 0;
        transform: translateY(5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-in-down {
    0% {
        opacity: 0;
        transform: translateY(-5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoom-in {
    0% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes logo-in {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(4px);
    }
}

html.cssanimations {
  .fade-in,
  .fade-in-up,
  .fade-in-down,
  .fade-in-left,
  .fade-in-right {
    opacity: 0;
  }
}

.fade-in {
  animation: fade-in 0.5s ease-out;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

.bob {
  animation: bob 3s ease-out infinite;
}

.fade-in-right {
  opacity: 0;
  &.fade-in {
    animation: fade-in-right 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-left {
  opacity: 0;
  &.fade-in {
    animation: fade-in-left 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-up {
  opacity: 0;
  &.fade-in {
    animation: fade-in-up 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.fade-in-down {
  opacity: 0;
  &.fade-in {
    animation: fade-in-down 1.2s cubic-bezier(0, 0, 0.13, 0.98);
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}
