input[type=checkbox], input[type=radio] {
    //Resets
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline-block;

    margin-right: $unit;
}

span.wpcf7-list-item {

    &.first {
        margin-left: 0;
    }
    
    .wpcf7-list-item-label {
        margin-right: $unit * 2;
    }
}

input[type=checkbox] {
    appearance: checkbox;
}

input[type=radio] {
    appearance: radio;
}