$unit: 0.5rem;

//Shortcut for Media Queries
@mixin breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
  @else {
    @warn "Couldn't find a breakpoint named `#{$name}`.";
  }
}

//Shortcut for targetting SVG path types
@mixin svg-paths {
  path,
  circle,
  polygon,
  line,
  polyline,
  rect {
    @content;
  }
}

//Allows hex colours in Data URIs
@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1);
}

//Generates vendor prefixes for placeholders
@mixin placeholder {
  &::-webkit-input-placeholder {@content};
  &:-moz-placeholder           {@content};
  &::-moz-placeholder          {@content};
  &:-ms-input-placeholder      {@content};
}


@mixin rhythm {
  > * {
    margin-bottom: $unit * 6;
  }

  > p + p,
  >h2 + p,
  >h3 + p,
  >h4 + p,
  >h5 + p,
  >h6 + p {
      margin-top: $unit * -3;
  }

  //Max width for certain elements in content sections to force correct character per line

  p, blockquote, li {
      max-width: 48em;
  }
}

@import "type-scale";

// px to rem for font-sizes and line-height
@mixin font-size($sizeValue: 1.6, $line: $sizeValue * 1.25){
    font-size: calc($sizeValue * 1) + px;
    line-height: calc($line * 1) + px;
    font-size: calc($sizeValue / 16) + rem;
    line-height: calc($line / 16) + rem;
}
// usage: @include font-size(13);

// ------------------------------------------------------------------------------------- //
// ------------------------------- GLOBAL VENDOR PREFIX -------------------------------- //
// ------------------------------------------------------------------------------------- //


/*
    Usage: @include prefixer(border-radius, $radii, webkit ms spec);
*/

$prefix-for-webkit:    true !default;
$prefix-for-mozilla:   true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera:     true !default;
$prefix-for-spec:      true !default; // required for keyframe mixin

@mixin prefixer ($property, $value, $prefixes) {
  @each $prefix in $prefixes {

    @if $prefix == webkit and $prefix-for-webkit == true {
      -webkit-#{$property}: $value;
    }
    @else if $prefix == moz and $prefix-for-mozilla == true {
      -moz-#{$property}: $value;
    }
    @else if $prefix == ms and $prefix-for-microsoft == true {
      -ms-#{$property}: $value;
    }
    @else if $prefix == o and $prefix-for-opera == true {
      -o-#{$property}: $value;
    }
    @else if $prefix == spec and $prefix-for-spec == true {
      #{$property}: $value;
    }
    @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin transition ($properties...) {
  @if length($properties) >= 1 {
    @include prefixer(transition, $properties, webkit moz ms o spec);
  }

  @else {
    $properties: all 0.15s ease-out 0;
    @include prefixer(transition, $properties, webkit moz ms o spec);
  }
}

@mixin box-sizing ($box) {
//  content-box | border-box | inherit
  @include prefixer(box-sizing, $box, webkit moz spec);
}


@mixin transform($property: none) {
//  none | <transform-function>
  @include prefixer(transform, $property, webkit moz ms o spec);
}

@mixin transform-origin($axes: 50%) {
// x-axis - left | center | right  | length | %
// y-axis - top  | center | bottom | length | %
// z-axis -                          length
  @include prefixer(transform-origin, $axes, webkit moz ms o spec);
}


// -------------------------------------------------------------------------------------- //
// ------------------------------- ANIMATION RELATED MIXINS ----------------------------- //
// -------------------------------------------------------------------------------------- //


// accelerate all the hardware for smoothers css transtions and transforms
@mixin hardwareAccelerate {
  -webkit-transform: translate3d(0,0,0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;

  -moz-transform: translate3d(0,0,0);
  -moz-perspective: 1000;
  -moz-backface-visibility: hidden;

  -ms-transform: translate3d(0,0,0);
  -ms-perspective: 1000;
  -ms-backface-visibility: hidden;

  transform: translate3d(0,0,0);
  perspective: 1000;
  backface-visibility: hidden;
}

// ------------------------------------------------------------------------------ //
// --------------------------- Animation Easing Types --------------------------- //
// ------------------------------------------------------------------------------ //

$easeIn: cubic-bezier(0.825, 0.000, 1.000, 0.640);
$easeInOut: cubic-bezier(0.665, 0.000, 0.430, 1);
$easeOut: cubic-bezier(0.200, 0.470, 0.435, 0.970);
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);

// usage:
// e.g. @include transition(.6s $easeInOut all);
