.gallery-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-white;
    display: flex;
    @extend %transition;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 100;

        @include breakpoint(S) {
            display: flex;
        }
    }
    .gallery-modal-images {
        width: calc(100% - 4rem);
        position: relative;

        @include breakpoint(S) {
            width: 100%;
            margin-top: 4.5rem;
            height: calc(100% - 4.5rem);
        }
        .button {
            position: absolute;
            z-index: 3;
            bottom: 1.75rem;
            right: 2rem;
            margin: 0;
        }
        .gallery-modal-slider,
        .slick-list,
        .slick-track,
        .flickity-viewport,
        .flickity-slider,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .gallery-modal-slide {
            height: 100vh;
            width: calc(100vw - 5rem);

            @include breakpoint(S) {
                height: calc(100vh - 4.5rem);
                width: 100vw;
            }
        }
        img {
            @include breakpoint(S) {
                object-fit: contain;
                font-family: 'object-fit: contain;';
            }
        }
        .modal-img-blanker {
            position: absolute;
            top: 0;
            left: -1px;
            z-index: 1;

            @include breakpoint(S) {
                display: none;
            }
        }
    }
    .gallery-modal-counter {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 1.125rem;
        padding-top: 1rem;

        .slash {
            margin: 0 0.25rem;
        }
        @include breakpoint(S) {
            margin: 0 1rem;
            padding-top: 0;
        }
    }
    .gallery-modal-slide {
        p {
            position: absolute;
            bottom: 1.75rem;
            font-weight: 500;
            left: 2rem;
            z-index: 3;
            margin: 0;
            color: $color-white;
        }
        @include breakpoint(S) {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            background: $color-body;
            justify-content: center;
            text-align: center;

            .button {
                position: static;
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#020722+0,020722+100&0+0,0.5+100 */
            background: -moz-linear-gradient(top, rgba(2,7,34,0) 0%, rgba(2,7,34,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(2,7,34,0) 0%,rgba(2,7,34,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00020722', endColorstr='#80020722',GradientType=0 ); /* IE6-9 */
        }
    }
    .gallery-modal-sidebar {
        width: 5rem;
        position: relative;
        color: $color-body;
        background: $color-white;

        @include breakpoint(S) {
            width: 100%;
            height: 4.5rem;
            position: fixed;
            top: 0;
            z-index: 15;
        }
        .gallery-sidebar-edge-horz {
            display: none;
        }
    }
    .gallery-modal-close.button {
        position: absolute;
        top: 1rem;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);
        @extend %transition;

        svg {
            display: block;
        }
        &:hover {
            transform: translateX(-50%) rotate(180deg);
        }
        @include breakpoint(S) {
            right: 2rem;
            left: auto;
            transform: none;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    .gallery-modal-controls {
        position: absolute;
        width: 4em;
        right: 0.5rem;
        bottom: 0.5rem;
        z-index: 100;

        button {
            margin: 0 auto 0.5rem auto;
            display: block;

            @include breakpoint(S) {
                margin-bottom: 0;
                margin-right: 0.5rem;

                &.gallery-modal-prev {
                    order: -1;
                }
            }
        }
        @include breakpoint(S) {
            right: auto;
            left: 2rem;
            bottom: 50%;
            transform: translateY(50%);
            width: auto;
            display: flex;
            align-items: center;
        }
    }
}

.custom-modal-close {
    position: fixed;
    top: 3%;
    right: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(6px);

    &.popup-mode{
        position: sticky;
        top: 8%;
        left: 69%;

        @include breakpoint(XS)  {
            left: 80%;
            top: 10%;
        }
    }

    svg {
        width: 1.2em;
        display: block;
        @extend %transition;
    }

    &:hover {

        svg {
            transform: rotate(180deg)
        }
    }
}

.inpage-modal,
.fancybox-slide .inpage-modal {
    text-align: center;
    width: 70vw;
    max-width: 56rem;
    padding: 5rem;
    background: #fff;
    background: var(--color-bg);
    background-size: 100%;
    margin: 5rem auto;

    @include breakpoint(S) {
        width: 90vw;
        padding: 5rem;
    }
    @include breakpoint(XS) {
        padding: 3rem 2rem;
    }
    ul {
        width: 50%;
        float: left;
        text-align: left;
        padding: 0 1rem;

        @include breakpoint(S) {
            width: 100%;
            float: none;
            padding: 0;
        }
        li {
            font-size: 0.875rem;

            &:before {
                background: $color-primary;
                background: var(--color-active);
                width: 6px;
                height: 6px;
                top: 0.5rem;
            }
        }
    }
}

.fancybox-button--fullscreen {
    display: none !important;
}

body .fancybox-button--close {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    border-radius: 100%;
    background: #fff;
    background: var(--color-reverse);
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 11;
    font-size: 1rem;
    @extend %transition;

    @include breakpoint(XS) {
        top: 1.25rem;
        left: 1.25rem;
    }
    &:before,
    &:after {
        display: block;
        background: $color-primary;
        background: var(--color-body);
        position: absolute;
        left: 1.125rem;
        top: 2.125rem;
        width: 2.25em;
        border-radius: 2px;
        opacity: 1;
        height: 4px;
        @extend %transition;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        background: #fff;
        background: var(--color-bg);

        &:before,
        &:after {
            background: $color-body;
            background: var(--color-body);
        }
    }
}
