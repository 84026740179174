.event-modal-single {
    background-color: $color-white-off;
    display: flex;
    flex-direction: column;

    @media only screen and (orientation: portrait) {
        display: block;
    }

    &.active {
        height: auto;
        @include breakpoint(XS) {
            display: block;
        }
    }

    &.scrolled-bottom {
        .from {
            left: 150%;
            border: 1px solid mix(white, $color-primary, 50%);
            color: $color-primary;
        }
        .book-room {
            left: 150%;
        }
    }
}

.event-modal-images-single {
    width: 100%;
    position: relative;
    height: calc(40rem + 4.5rem);

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 15%, rgba(0, 0, 0, 0) 100%);
        z-index: 2;
      }

    @media only screen and (orientation: portrait) {
        width: 100%;
        height: calc(33.75rem + 4.5rem);
    }

    img {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .event-single-content{
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        max-width: 37rem;
        text-align: center;
        transform: translate(-50%, -50%);
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        color: $color-white;

        @include breakpoint(S) {
            gap: 1rem;
            width:  22rem;
        }
            
        .event-single-header{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
           // text-transform: uppercase;
            //font-family: $font-stack-narrow;
        }
    }

    .flickity-page-dots {
        bottom: 4rem;

        .dot {
            background-color: $color-white-50;

            &.is-selected {
                background-color: $color-white;
            }
        }
    }

    .room-modal-slide {
        position: relative;
        width: 100%;

        &:before {
            content: "";
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%)
        }

        &:after {
            content: "";
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.35) 50%);
        }
    }
}

.event-single-description{
    padding-bottom: 3rem;
    display: inline-flex;
    width: 100%;
    @include breakpoint(XS) {
        flex-direction: column;
    }
        
    .event-single-details{
        display: flex;
        width: 33.5%;
        padding: 6rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
        background: $color-green;
        color: $color-white;
        @include breakpoint(MS) {
            padding: 1.96875rem 1.75rem;
            gap: 1.95rem;
        }
        @include breakpoint(XS) {
            width: 100%;
        }
        a{
            color: white;
            h3{
                color: white;
            }
        }
        h3{
            //text-transform: uppercase;
            font-family: $font-stack-narrow;

            svg{
                margin-left: .25rem;
            }
        }
        .event-details{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            @include breakpoint(S) {
                gap: 1.31rem;
            }
            
            small{
                display: flex;
                gap: 1rem;

                .event-icon{
                    color: $color-accent;
                }
                .event-title{
                    font-family: $font-stack-narrow;
                    @include size-M;
                }
            }
        }

    }

    .event-single-text{
        display: flex;
        width: 66.5%;
        padding: 6rem 10rem;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
        align-self: stretch;
        background: $color-accent;
        @include breakpoint(MS) {
            padding: 1.96875rem 1.75rem;
            gap: 1.96875rem;
        }
        @include breakpoint(XS) {
            width: 100%;
        }

        p{
            letter-spacing: unset;
            font-family: $font-stack-primary;
        }
        .modal-contact{
            max-width: 40rem;
            align-items: center;
            gap: 2.25rem;
            align-self: baseline;
            @include breakpoint(XS) {
                gap: 1.25rem;
            }

            .button-primary:hover{
                background: $color-green-50;
            }
            .button-call{
                display: flex;
                border: 1.5px solid #F9FCFA80;
                gap: .625rem;
                .call-text{
                    font-weight: 400;
                }
            }
        }
    }
}

// .single-section{
//     padding: 3rem 0;
//     align-items: center;
// }