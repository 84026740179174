// Fonts
$font-stack-primary: 'Georgia', 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', 'P052', serif;
$font-stack-secondary: 'Raleway', 'Bahnschrift', 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow','sans-serif-condensed', sans-serif;
$font-stack-narrow:  'Raleway', 'Bahnschrift', 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow','sans-serif-condensed', sans-serif;

$font-stack-system: $font-stack-primary;

// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  23.750em ),
    'XS': ( max-width:  40em ),
    'S': ( max-width:  48em ),
    'S-up': ( min-width: 769px ),
    'M': ( max-width:  64em ),
    'MS': ( max-width:  52em ),
    'M-up': ( min-width:  64em ),
    'ML': ( max-width:  76.250em ),
    'ML-up': ( min-width:  76.250em ),
    'L': ( max-width: 91em ),
    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em )
);
