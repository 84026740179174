/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    position: absolute;
    bottom: -2.5rem;
    margin: 0;
    left: 0;
    width: 100%;
    font-size: 0;
    text-align: center;

    .content-img-carousel__carousel & {
        width: auto;
        right: 10%;
        left: auto;

        .image-text & {
            right: auto;
            left: 10%;
        }

        @include breakpoint (S) {
            right: 0;
        }
    }

    li {
        padding: 0;
        margin-right: 1.125rem;
        display: inline-block;
        vertical-align: middle;

        @include breakpoint (XXS) {
            margin-right: 0.5rem;
        }

        &:last-child {
            margin-right: 0;
        }

        &:only-child {
            display: none;
        }
        &:before {
            display: none;
        }
        button {
            display: block;
            min-width: 0;
            color: transparent;
            border-radius: 100%;
            padding: 0;
            width: 0.8rem;
            height: 0.8rem;
            background-color: $color-green-12;
            border: none;
            margin: 0;

            @include breakpoint (XXS) {
                width: 0.8rem;
                height: 0.8rem;
            }

            &:before,
            &:after {
                display: none;
            }
        }
        &.slick-active {
            button {
                background-color: $color-green;
                width: 1rem;
                height: 1rem;

                @include breakpoint (XXS) {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }
        }
    }
}

.slick-slide:focus {
    outline: 0;
}

.slick-button-wrap {
  position: absolute;
  top: 0;
  height: 100%;
  transform: none;
  width: 8.333%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    top: 2em;
    left: 0;
    bottom: 2em;
    width: calc(100% + 0.5em);
    border: 1px solid rgba($color-body, 0.2);

    @include breakpoint(XS) {
      display: none;
    }
  }
  &:hover {
    svg {
      polygon {
        fill: $color-body;
      }
    }
  }
  button {
    position: absolute;
    border: 0;
    min-width: 0;
    padding: 0.75em 0;
    background: #f7f7f7;
    border: 0;
    top: 50%;
    z-index: 1;

    svg {
      path,
      polygon {
        @extend %transition;
      }
    }
    @include breakpoint(XS) {
      background: transparent;
      padding: 0.75em 0em;

      svg {
        path,
        polygon {
          fill: #fff;
        }
      }
    }
  }
  &.slick-next-wrap {
    left: 100%;

    @include breakpoint(XS) {
      right: 0;
      left: auto;
    }
    &:after {
      border-left: 0;
      left: auto;
      right: 0;
    }
    button {
      right: 0;
      transform: translate(50%, -50%);

      @include breakpoint(XS) {
        transform: translate(0, -50%);
        right: 0;
      }
    }
  }
  &.slick-prev-wrap {
    right: 100%;
    left: auto;

    @include breakpoint(XS) {
      right: auto;
      left: 0;
    }
    &:after {
      border-right: 0;
    }
    button {
      left: 0;
      transform: translate(-50%, -50%);

      @include breakpoint(XS) {
        transform: translate(0, -50%);
        left: 0;
      }
    }
  }
}

.slick-arrow {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    top: 50%;
    padding: 0.5em;
    transform: translateY(-50%);

    svg {
        @extend %transition;
    }
    &.slider-prev {
        left: 0;

        &:hover {
            svg {
                transform: translateX(-2px);
            }
        }
    }
    &.slider-next {
        right: 0;

        &:hover {
            svg {
                transform: translateX(2px);
            }
        }
    }
    &.bottom {
        border-radius: 100%;
        border: 2px solid var(--color-accent2);
        top: auto;
        bottom: -4rem;
        padding: 0;
        width: 2.625rem;
        height: 2.625rem;

        &.slider-prev {
            left: auto;
            right: calc(50% + 0.5rem);
        }
        &.slider-next {
            left: calc(50% + 0.5rem);
            right: auto;
        }
    }
}


.slick-arrow {
    @extend %transition;
}
